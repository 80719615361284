import React from "react"

import Layout from "../hoc/Layout"
import Swiper from "../components/Swiper/Swiper"

export default () => {
  return (
    <Layout bodyClass="home">
      <Swiper />
    </Layout>
  )
}
