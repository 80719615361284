import React from "react"
import { Link } from "gatsby"

// import Swiper core and required components
import SwiperCore, { Pagination, Autoplay } from "swiper"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "../../style/swiper/swiper.scss"
import "../../style/swiper/pagination.scss"

// install Swiper components
SwiperCore.use([Pagination, Autoplay])

export default () => {
  return (
    <Swiper autoplay={{ delay: 6500 }} spaceBetween={0} slidesPerView={1} pagination={{ clickable: true }} className="main-slider">
      {/* // Slide 1 */}
      <SwiperSlide style={{ backgroundColor: "#1E1E40" }}>
        <div className="main-swiper-slide-inner">
          <div className="main-swiper-slide-inner-content">
            <h1>
              Discover
              <br />a world of
              <br />
              children's&nbsp;books
            </h1>
            <Link to="/about-us" className="btn">
              Learn more
            </Link>
          </div>
          <div className="slide slide-1"></div>
        </div>
      </SwiperSlide>

      {/* // Slide 2 */}
      <SwiperSlide style={{ backgroundColor: "#FFBF2A" }}>
        <div className="main-swiper-slide-inner">
          <div className="main-swiper-slide-inner-content swiper-style-2">
            <p>About Us</p>
            <h1>
              Choose&nbsp;from
              <br />
              a&nbsp;wide&nbsp;range
              <br />
              of&nbsp;publishers
            </h1>
            <Link to="/about-us#publishers" className="btn">
              Learn more
            </Link>
          </div>
          <div className="slide slide-2"></div>
        </div>
      </SwiperSlide>

      {/* // Slide 3 */}
      <SwiperSlide style={{ backgroundColor: "#672C5E" }}>
        <div className="main-swiper-slide-inner">
          <div className="main-swiper-slide-inner-content swiper-style-2">
            <p>Bookselling</p>
            <h1>
              Join&nbsp;our
              <br />
              network
              <br />
              of&nbsp;booksellers
            </h1>
            <Link to="/bookselling" className="btn">
              Learn more
            </Link>
          </div>
          <div className="slide slide-3"></div>
        </div>
      </SwiperSlide>

      {/* // Slide 4 */}
      <SwiperSlide style={{ backgroundColor: "#3F59A1" }}>
        <div className="main-swiper-slide-inner">
          <div className="main-swiper-slide-inner-content swiper-style-4">
            <p>School Book Fairs</p>
            <h1>
              Inspire&nbsp;a&nbsp;love
              <br />
              of&nbsp;reading
            </h1>
            <Link to="/school-book-fairs" className="btn">
              Learn more
            </Link>
          </div>
          <div className="slide slide-4"></div>
        </div>
      </SwiperSlide>

      {/* // Slide 5 */}
      <SwiperSlide style={{ backgroundColor: "#FFBF2A" }}>
        <div className="main-swiper-slide-inner">
          <div className="main-swiper-slide-inner-content swiper-style-2">
            <p>Support</p>
            <h1>
              Enjoy&nbsp;full
              <br />
              business
              <br />
              support
            </h1>
            <Link to="/support" className="btn">
              Learn more
            </Link>
          </div>
          <div className="slide slide-5"></div>
        </div>
      </SwiperSlide>

      {/* // Slide 6 */}
      <SwiperSlide style={{ backgroundColor: "#ACCB5D" }}>
        <div className="main-swiper-slide-inner">
          <div className="main-swiper-slide-inner-content swiper-style-2">
            <p>Events</p>
            <h1>
              Request
              <br />
              a&nbsp;bookstall
              <br />
              at&nbsp;your&nbsp;event
            </h1>
            <Link to="/events" className="btn">
              Learn more
            </Link>
          </div>
          <div className="slide slide-6"></div>
        </div>
      </SwiperSlide>

      {/* // Slide 7 */}
      <SwiperSlide style={{ backgroundColor: "#672C5E" }}>
        <div className="main-swiper-slide-inner">
          <div className="main-swiper-slide-inner-content swiper-style-5">
            <p>Contact</p>
            <h1>
              Ask&nbsp;for&nbsp;more
              <br />
              information
            </h1>
            <Link to="/contact" className="btn">
              Get in touch
            </Link>
          </div>
          <div className="slide slide-7"></div>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}
